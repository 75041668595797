import {Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Typography} from "antd";
import dayjs, {Dayjs} from "dayjs";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import styles from './FactoryDetails.module.css';
import {authApi, validate} from "../../resources/authService";
import {keycloak} from "../../resources/keycloak";

const { Title } = Typography;

interface Factory_Details {
    name: string;
    location: string;
    date_of_commencement_of_operation: Dayjs;
    date_of_cleaning_of_bioreactor: Dayjs;
    electric_power: number;
    max_daily_electric_energy: number;
    daily_production_of_biogas: number;
    daily_production_of_methane: number;
    active_volume_of_bioreactor: number;
    daily_supply: number;
    hydraulic_waiting_time: number;
    organic_pace_of_charging: number;
}


function FactoryDetails () {
    const [form] = Form.useForm();
    const companyName = localStorage.getItem('companyName');
    const navigate = useNavigate();


    useEffect(() => {
        const init = async () => {
            if (! await validate()){
                navigate('/');
            } else {
                const userId = keycloak.tokenParsed?.sub ?? "";
                authApi.get(`factory_details/getByName/${userId}`)
                    .then(response => {
                        // Assuming the response data has the same structure as your form
                        const data = response.data;

                        if(data.location === "" || data.location===undefined || data.location===null) {
                            form.setFieldsValue({
                                name: data.name
                            });
                            localStorage.setItem('companyName', data.name);
                        } else {
                            form.setFieldsValue({
                                name: data.name,
                                location: data.location,
                                // Convert dates to Dayjs objects if necessary
                                date_of_commencement_of_operation: data.date_of_commencement_of_operation ? dayjs(data.date_of_commencement_of_operation) : null,
                                date_of_cleaning_of_bioreactor: data.date_of_cleaning_of_bioreactor ? dayjs(data.date_of_cleaning_of_bioreactor) : null,
                                electric_power: data.electric_power,
                                max_daily_electric_energy: data.max_daily_electric_energy,
                                daily_production_of_biogas: data.daily_production_of_biogas,
                                daily_production_of_methane: data.daily_production_of_methane,
                                active_volume_of_bioreactor: data.active_volume_of_bioreactor,
                                daily_supply: data.daily_supply,
                                hydraulic_waiting_time: data.hydraulic_waiting_time,
                                organic_pace_of_charging: data.organic_pace_of_charging,
                            });
                            localStorage.setItem('companyName', data.name);
                        }

                    })
                    .catch(error => {
                        console.error('Error fetching factory details:', error);
                        message.error('Error fetching factory details.');
                    });
            }
        };

        init();


    }, [companyName, form, navigate]);


    const handleSubmit = () => {
        form.validateFields().then((values) => {

            const {
                name,
                location,
                date_of_commencement_of_operation,
                date_of_cleaning_of_bioreactor,
                electric_power,
                max_daily_electric_energy,
                daily_production_of_biogas,
                daily_production_of_methane,
                active_volume_of_bioreactor,
                daily_supply,
                hydraulic_waiting_time,
                organic_pace_of_charging,
            } = values;

            const utcDate_of_commencement_of_operation = dayjs(date_of_commencement_of_operation).utc(true).hour(12).minute(0).second(0).millisecond(0);
            const utcDate_of_cleaning_of_bioreactor = dayjs(date_of_cleaning_of_bioreactor).utc(true).hour(12).minute(0).second(0).millisecond(0);


            const factoryDetails: Factory_Details = {
                name,
                location,
                date_of_commencement_of_operation: utcDate_of_commencement_of_operation,
                date_of_cleaning_of_bioreactor: utcDate_of_cleaning_of_bioreactor,
                electric_power,
                max_daily_electric_energy,
                daily_production_of_biogas,
                daily_production_of_methane,
                active_volume_of_bioreactor,
                daily_supply,
                hydraulic_waiting_time,
                organic_pace_of_charging,
            };


            authApi.put(`factory_details/update`, factoryDetails)
                    .then(response => {
                        console.log(response.data);
                        message.success(response.data);
                        // You can navigate or perform other actions after successful POST
                    })
                    .catch(error => {
                        console.error('Error:', error.error.message);
                        message.error('Error:', error.error.message);
                    });

        });
    };



    const validateNumber = (_:any, value:any) => {
        if (value && isNaN(value)) {
            return Promise.reject(new Error('Please enter a valid number'));
        }
        else if (value && value.toString().includes('.')) {
            return Promise.reject(new Error('Please enter a valid number'));
        }
        return Promise.resolve();
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: '50px'
            }}
        >
            <div className={styles['factory-details-container']}>
                <Title>Unit Information</Title>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    style={{
                        marginTop: '20px',
                        width: '100%'
                    }}
                >
                    <Row gutter={[48, 16]}>
                        <Col span={8} >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input the unit name!' }]}
                            >
                                <Input disabled placeholder="Add unit name"/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Location"
                                name="location"
                                rules={[{ required: true, message: 'Please input the location!' }]}
                            >
                                <Input placeholder="Add unit location"/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Date of Commencement of Operation"
                                name="date_of_commencement_of_operation"
                            >
                                <DatePicker style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[48, 16]}>
                        <Col span={8}>
                            <Form.Item
                                label="Date of Cleaning of Bioreactor"
                                name="date_of_cleaning_of_bioreactor"
                            >
                                <DatePicker style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Electric Power"
                                name="electric_power"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Max Daily Electric Energy"
                                name="max_daily_electric_energy"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[48, 16]}>
                        <Col span={8}>
                            <Form.Item
                                label="Daily Production of Biogas"
                                name="daily_production_of_biogas"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<span style={{ fontWeight: 'bold' }}>Daily Production of Methane</span>}
                                name="daily_production_of_methane"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Active Volume of Bioreactor"
                                name="active_volume_of_bioreactor"
                                rules={[{ validator: validateNumber }]}
                                >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[48, 16]}>
                        <Col span={8}>
                            <Form.Item
                                label={<span style={{ fontWeight: 'bold' }}>Daily Supply</span>}
                                name="daily_supply"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Hydraulic Waiting Time"
                                name="hydraulic_waiting_time"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Organic Pace of Charging"
                                name="organic_pace_of_charging"
                                rules={[{ validator: validateNumber }]}
                            >
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item style={{marginTop:'30px'}}>
                        <Button type="primary" htmlType="submit" size={"large"} style={{width: '20%'}}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>

            </div>
        </div>
    );
}

export default FactoryDetails;