import dayjs, {Dayjs} from "dayjs";
import {useCallback, useEffect, useState} from "react";
import {AutoComplete, Button, DatePicker, Input, Space, Table, Typography} from "antd";
import AnalysModal from "../../components/analyses/AnalysModal";
import {authApi, getUserId, validate} from "../../resources/authService";
import {useNavigate} from "react-router-dom";

const { Title } = Typography;

interface AnalysesType {
    id: number;
    name: string;
    type: string;
    date: Dayjs; // LocalDate is serialized to a string in JSON
    source: string;
    available_in_source: number;
    available_in_unit: number;
    distance: number;
    cost_of_purchase: number;
    cost: number;
    total_solids: number;
    volatile_solids: number;
    carbs: number;
    proteins: number;
    fat: number;
    nh4: number;
    volatile_fatty_acids: number;
    biogas_potential: number;
    methane_potential: number;
}


interface OptionType {
    value: string;
    label: React.ReactNode;
}

function Analyses () {
    const [selectedRowData, setSelectedRowData] = useState<AnalysesType | null>(null);
    const [searchParams, setSearchParams] = useState({
        name: "",
        type: "",
        source: "",
        date: ""
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState<AnalysesType[]>([]);
    const dateFormat = 'YYYY-MM-DD';
    const navigate = useNavigate();
    const companyName = getUserId();



    const handleNameSearch = (value: string) => {
        setSearchParams(prev => ({ ...prev, name: value }));
    };

    const handleTypeSearch = (value: string) => {
        setSearchParams(prev => ({ ...prev, type: value }));
    };

    const handleSourceSearch = (value: string) => {
        setSearchParams(prev => ({ ...prev, source: value }));
    };

    const handleDateChange = (date: Dayjs | null, dateString: string) => {
        setSearchParams(prev => ({ ...prev, date: dateString }));
    };


    const handleChange = useCallback((query: any) => {

        if (!query) {


            authApi.get(`analyses/getAll?factoryName=${companyName}`)
                .then(response => {
                    setData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setData([]);
                });
        } else {

            console.log(searchParams.name)
            console.log(searchParams.type)
            console.log(searchParams.source)
            console.log(searchParams.date)

            authApi.get(`analyses/search`, {
                params: {
                    name: searchParams.name,
                    type: searchParams.type,
                    source: searchParams.source,
                    date: searchParams.date ? dayjs(searchParams.date).format('YYYY-MM-DD') : null,
                    factoryName: companyName
                }
            }).then(response => {
                setData(response.data);
            })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setData([]);
                });
        }
    }, [companyName, searchParams.name, searchParams.type, searchParams.source, searchParams.date]);


    useEffect(() => {
        // Fetch data from the API

        const init = async () => {
            if (! await validate()){
                navigate('/');
            } else {
                if (searchParams.date || searchParams.type || searchParams.source || searchParams.name) {
                    handleChange(searchParams);
                } else {
                    // Clear the options when searchValue is empty
                    handleChange("");
                }
            }
        };

        init();

        
    }, [searchParams.date, searchParams.type, searchParams.name, searchParams.source, searchParams, handleChange, navigate]);


    const onClick = () => {
        setSelectedRowData(null)
        setModalVisible(true);
    }


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date:Dayjs) => dayjs(date).format(dateFormat),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: () => viewButton(), // Render viewButton for each row
        },
        // Add more columns as needed
    ];

    const viewButton = () => (
        <Button onClick={() => handleView()}>
            View
        </Button>
    );

    const handleView = () => {
        setModalVisible(true);
    }

    const onRow = (record: AnalysesType) => {
        return {
            onClick: () => {
                setSelectedRowData(record);
            },
        };
    };

    const handleModalClose = () => {
        setModalVisible(false); // Hide the modal
        if (searchParams.date || searchParams.type || searchParams.source || searchParams.name) {
            handleChange(searchParams);
        } else {
            // Clear the options when searchValue is empty
            handleChange("");
        }
    }



    return (
        <div style={{padding: '20px'}}>
            <Title>Analyses</Title>
            <Space style={{marginTop: '30px', width: '100%', justifyContent: 'space-between'}}>
                <Space>
                    <AutoComplete<OptionType>
                        onSearch={(value) => handleNameSearch(value)}
                    >
                        <Input placeholder="Search by Name"/>
                    </AutoComplete>
                    <AutoComplete<OptionType>
                        onSearch={(value) => handleTypeSearch(value)}
                    >
                        <Input placeholder="Search by Type"/>
                    </AutoComplete>
                    <AutoComplete<OptionType>
                        onSearch={(value) => handleSourceSearch(value)}
                    >
                        <Input placeholder="Search by Source"/>
                    </AutoComplete>
                    <DatePicker onChange={(date, dateString) => handleDateChange(date, dateString as string)} format={dateFormat} />
                </Space>



                <Button type="primary" onClick={onClick}>
                    Add Analyses
                </Button>
            </Space>

            <Table style={{marginTop: '50px'}} dataSource={data} columns={columns} onRow={onRow} rowKey= "id"
                   scroll={{y: 325}}/>

            <AnalysModal isOpen={modalVisible} onClose={handleModalClose} data={selectedRowData} id={selectedRowData?.id}/>
        </div>
    );
}

export default Analyses;