import dayjs, {Dayjs} from "dayjs";
import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, InputNumber, message, Modal, Row, Select, Space} from "antd";
import utc from 'dayjs/plugin/utc'; // import the plugin
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {authApi, getUserId} from "../../resources/authService";

dayjs.extend(utc); // use plugin

interface SupplyProps {
    isOpen: boolean;
    onClose: () => void; // Optional prop
    data?: SupplyDto | null;
    id?: number;
}

interface StorageDto {
    analysesId: number;
    analysesName: string;
    volume: number;
}

interface SupplyDto {
    date: Dayjs;
    storages: StorageDto[];
}

interface SupplyDtoCreate {
    factoryName: string;
    date: Dayjs;
    storages: StorageDto[];
}

interface Analysis {
    id: number;
    name: string;
    // Include other properties as necessary
}


const SupplyModal: React.FC<SupplyProps> = ({ isOpen, onClose, data , id}) => {
    const [form] = Form.useForm();
    const [analysesOptions, setAnalysesOptions] = useState<Analysis[]>([]);
    const companyName = getUserId();


    useEffect(() => {
        authApi.get(`analyses/getAllForSupply?factoryName=${companyName}`).then(response => {
            setAnalysesOptions(response.data.map((analysis: Analysis) => ({
                label: analysis.name, // Display name
                value: analysis.id,   // Corresponding ID
            })));
        })
            .catch(error => {
                message.error(error.message);
            });

        if (data) {
            form.setFieldsValue({
                date: dayjs(data.date),
                storages: data.storages
            });
        } else {
            form.resetFields();
        }
    }, [companyName, data, form, id]);


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const { date, storages } = values;


            console.log(dayjs(date))


            const utcDate = dayjs(date).utc(true).hour(12).minute(0).second(0).millisecond(0);



            console.log(utcDate)

            const supplyDto: SupplyDtoCreate = {
                factoryName: companyName ? companyName : "",
                date: utcDate,
                storages: storages.map((storage : StorageDto) => ({
                    analysesId: storage.analysesId || 0,
                    volume: storage.volume,
                })),
            };

            console.log(supplyDto)

            const endpoint = data ? `supply/update/${id}` : `supply/create`;
            const method = data ? authApi.put : authApi.post;

            method(endpoint, supplyDto).then(() => {
                message.success('Operation successful');
                form.resetFields();
                onClose();
            }).catch(() => {
                message.error('Operation failed');
            });
        });
    };


    const validateNumber = (_:any, value:any) => {
        if (value && isNaN(value)) {
            return Promise.reject(new Error('Please enter a valid number'));
        }
        else if (value && value.toString().includes('.')) {
            return Promise.reject(new Error('Please enter a valid integer'));
        }
        return Promise.resolve();
    };

    if (!isOpen) {
        return null;
    }

    const onDelete = () => {
        authApi.delete(`supply/delete/${id}`)
            .then(() => {
                console.log(`Fertilizer with ID ${id} has been deleted.`);
                onClose();
                // Assuming you want to update the UI after deletion, you can filter out the deleted medicine from the list
            })
            .catch(error => {
                console.error(`Error deleting analyses with ID ${id}:`, error);
            });
        onClose();
    };

    return(
        <div>
            <Modal
                title={data ? "Edit Supply" : "Add Supply"}
                open={isOpen}
                onCancel={onClose}
                footer={null}
                width="40%"
                centered
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical" autoComplete="off">
                    <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please select the date!' }]}>
                        <DatePicker style={{ width: '100%' }} disabled={!!data} />
                    </Form.Item>

                    <Form.List name="storages"
                               rules={[
                                   {
                                       validator: async (_, names) => {
                                           if (!names || names.length < 1) {
                                               return Promise.reject(new Error('Add at least 1 analyses record'));
                                           }
                                       },
                                   },
                               ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row key={key} gutter={10} align="middle">
                                        <Col flex="auto">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'analysesId']}
                                                label="Analyses Name"
                                                rules={[{ required: true, message: 'Missing analyses Name' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select analysis name"
                                                    options={analysesOptions} // Populate dropdown with options
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="label"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col flex="auto">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'volume']}
                                                label="Volume"
                                                rules={[{ required: true, message: 'Missing volume' }, { validator: validateNumber }]}
                                            >
                                                <InputNumber placeholder="Volume" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                        Add Analyses record
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Space size="large" style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <Button type="primary" onClick={handleSubmit}>
                            {data ? "Edit" : "Add"}
                        </Button>
                        {data && (
                            <Button type="primary" onClick={() => onDelete()} danger>
                                Delete
                            </Button>
                        )}
                        <Button onClick={onClose} danger>
                            Cancel
                        </Button>
                    </Space>
                </Form>
            </Modal>
        </div>
    );
}

export default SupplyModal;