import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, message, Row, Space, Table, TableProps, Typography} from "antd";
import {authApi, getUserId} from "../../resources/authService";
import dayjs from "dayjs";

const { Title, Text } = Typography;


interface ResultFormProps {
    isSubmit?: boolean;
    id: number;
}

interface DataType {
    key: string; // Unique key for each row
    name: string;
    quantity: number;
    price: number;
    methane: number;
    carbs: number;
    proteins: number;
    fat: number;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: <Text strong>Name</Text>,
        dataIndex: 'name',
        key: 'name',
    }, {
        title: <Text strong>Quantity</Text>,
        dataIndex: 'quantity',
        key: 'quantity',
    }, {
        title: <Text strong>Price</Text>,
        dataIndex: 'price',
        key: 'price',
    }, {
        title: <Text strong>Methane</Text>,
        dataIndex: 'methane',
        key: 'methane',
    }, {
        title: <Text strong>Carbs</Text>,
        dataIndex: 'carbs',
        key: 'carbs',
    }, {
        title: <Text strong>Proteins</Text>,
        dataIndex: 'proteins',
        key: 'proteins',
    }, {
        title: <Text strong>Fat</Text>,
        dataIndex: 'fat',
        key: 'fat',
    },
];

const ResultNeural : React.FC<ResultFormProps> = ({id}) => {
    const [date, setDate] = useState<string>('');
    const [methane, setMethane] = useState<string>('');
    const [data, setData] = useState<DataType[]>([]);
    const companyName = getUserId();


    const getData = useCallback((isSubmit: boolean, retryCount = 0) => {

        const loadingMessageKey = 'loadingMessage';
        const maxRetries = 3;

        if(isSubmit) {
            message.loading({ content: 'Operation loading...', key: loadingMessageKey, duration: 0 });
        }

        if (!companyName){
            return;
        }

        console.log(id)

        if ( id>=0 ) {
            authApi.get(`result/latest?id=${id}`)
                .then(response => {
                    const responseData = response.data;

                    console.log(0)

                    if (responseData) {
                        setDate(dayjs(responseData.date).format('YYYY-MM-DD, HH:mm:ss'));
                        if (responseData.total_methane_from_neural_network !== -404 && responseData.total_methane_from_neural_network !== 0)
                            setMethane(parseFloat(responseData.total_methane_from_neural_network.toFixed(4)).toString());
                        else if (isSubmit) {
                            if (retryCount < maxRetries) {
                                setTimeout(() => getData(isSubmit, retryCount + 1), 5000); // Retry after 5 seconds
                            } else {
                                message.error({ content: 'Neural network is unavailable after several attempts', key: loadingMessageKey, duration: 2 });
                            }
                        }

                        const storagesData: DataType[] = responseData.storages.map((storage: any, index: number) => ({
                            key: `storage_${index}`,  // Ensure each row has a unique key
                            name: storage.analysesName,
                            quantity: storage.quantity,
                            price: storage.price,
                            methane: storage.methane,
                            carbs: storage.carbs,
                            proteins: storage.protein,
                            fat: storage.fat,
                        }));

                        const totalRow: DataType = {
                            key: 'total',
                            name: 'Total',
                            quantity: responseData.total_quantity,
                            price: responseData.total_price,
                            methane: responseData.total_methane,
                            carbs: responseData.total_carbs,
                            proteins: responseData.total_protein,
                            fat: responseData.total_fat,
                        };

                        setData([...storagesData, totalRow]);

                        if(isSubmit && retryCount < maxRetries) {

                            if (responseData.total_methane_from_neural_network === -404 || responseData.total_methane_from_neural_network === 0) {

                                authApi.get(`result/neural?id=${id}`)
                                    .then(response => {
                                        // Assuming the response data has the same structure as your form
                                        const data = response.data;

                                        if (data.total_methane_from_neural_network !== -404 || data.total_methane_from_neural_network !== 0)
                                            setMethane(parseFloat(data.total_methane_from_neural_network.toFixed(4)).toString());
                                        else{
                                            setMethane("");
                                            message.error({ content: 'Neural network is unavailable', key: loadingMessageKey, duration: 2 });
                                        }


                                    })
                                    .catch(error => {
                                        console.error('Error fetching factory name:', error);
                                    });


                            } else
                                message.success({ content: 'Operation successful!', key: loadingMessageKey, duration: 2 });
                        }

                    } else if (isSubmit && retryCount < maxRetries) {
                        setTimeout(() => getData(isSubmit, retryCount + 1), 5000); // Retry logic
                    }


                })
                .catch(error => {


                    if (isSubmit && retryCount < maxRetries) {
                        setTimeout(() => getData(isSubmit, retryCount + 1), 5000);
                    }
                    else {
                        console.error('Error fetching data:', error);
                        message.error({ content: 'Operation failed. Please try again later.', key: loadingMessageKey, duration: 2 });
                    }
                });
        }


    }, [companyName, id]);


    useEffect(() => {
        getData(false);
    }, [getData]);


    const handleSubmitToNeural = () => {
        authApi.get(`result/neural?id=${id}`)
            .then(response => {
                // Assuming the response data has the same structure as your form
                const data = response.data;

                if (data !== -404 || data !== 0)
                    setMethane(parseFloat(data.total_methane_from_neural_network.toFixed(4)).toString());
                else{
                    setMethane("");
                    getData(true);
                }

            })
            .catch(() => {
                getData(true);
            });
    }




    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: '50px',
                flex: "auto",
            }}
        >
            <Row justify="center" align="middle" style={{width: '100%'}}>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Title>Result</Title>
                    <Space direction="vertical">
                        <Text mark><strong>Total methane from neural network:</strong> {methane}</Text>
                        <Text keyboard><strong>Creation date:</strong> {date}</Text>
                    </Space>
                    <Table style={{marginTop: '20px'}} dataSource={data} columns={columns} bordered rowKey="key"/>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button type="primary" disabled={data.length === 0} onClick={handleSubmitToNeural}>Send to neural</Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ResultNeural;