// @ts-ignore
import Keycloak from 'keycloak-js';

const uri = process.env.KEYCLOAK_API_URL || "smartnh4.duckdns.org/auth";

const keycloakConfig = {
    url: `https://${uri}/`, // Keycloak server URL
    realm: 'friendly_security', // Your realm name
    clientId: 'security-frontend', // Your client ID
};

// Create a new Keycloak instance with the configuration
const keycloak = new Keycloak(keycloakConfig);

export { keycloak };
