import dayjs, {Dayjs} from "dayjs";
import {useCallback, useEffect, useState} from "react";
import {Button, Space, Table, Typography} from "antd";
import SupplyModal from "../../components/supply/SupplyModal";
import {authApi, getUserId, validate} from "../../resources/authService";
import {useNavigate} from "react-router-dom";


const { Title } = Typography;


interface StorageDto {
    analysesId: number;
    analysesName: string;
    volume: number;
}

interface SupplyDto {
    id: number;
    date: Dayjs;
    storages: StorageDto[];
    carbs: number;
    proteins: number;
    fat: number;
}


function Supply () {
    const [selectedRowData, setSelectedRowData] = useState<SupplyDto | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState<SupplyDto[]>([]);
    const dateFormat = 'YYYY-MM-DD';
    const companyName = getUserId();
    const navigate = useNavigate();


    const getData = useCallback(() => {
        authApi.get(`supply/week?factoryName=${companyName}`)
            .then(response => {
                console.log(response.data)
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setData([]);
            });
    }, [companyName]);

    useEffect(() => {
        const init = async () => {
            if (! await validate()){
                navigate('/');
            } else {
                getData();
            }
        };

        init();
    },[getData, navigate])



    const onClick = () => {
        setSelectedRowData(null)
        setModalVisible(true);
    }


    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date:Dayjs) => dayjs(date).format(dateFormat),
        },{
            title: 'Carbs',
            dataIndex: 'carbs',
            key: 'carbs',
        },
        {
            title: 'Proteins',
            dataIndex: 'proteins',
            key: 'proteins',
        },
        {
            title: 'Fat',
            dataIndex: 'fat',
            key: 'fat',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: () => viewButton(), // Render viewButton for each row
        },
        // Add more columns as needed
    ];

    const viewButton = () => (
        <Button onClick={() => handleView()}>
            View
        </Button>
    );

    const handleView = () => {
        setModalVisible(true);
    }

    const onRow = (record: SupplyDto) => {
        return {
            onClick: () => {
                setSelectedRowData(record);
            },
        };
    };

    const handleModalClose = () => {
        setModalVisible(false); // Hide the modal
        getData();
    }


    return(
        <div style={{padding: '20px'}}>
            <Title>Weekly supply</Title>
            <Space style={{marginTop: '30px', width: '100%', justifyContent: 'space-between'}}>
                <Space>

                </Space>


                <Button type="primary" onClick={onClick}>
                    Add Supply
                </Button>
            </Space>

            <Table style={{marginTop: '50px'}} dataSource={data} columns={columns} onRow={onRow} rowKey="id"
                   />

            <SupplyModal isOpen={modalVisible} onClose={handleModalClose} data={selectedRowData}
                         id={selectedRowData?.id}/>
        </div>
    );
}

export default Supply;