import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminDashboard from './layout/AdminDashboard';
import Login from './pages/login/Login';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from './resources/keycloak';

const App = () => {
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{ onLoad: 'login-required' }} // Automatically trigger login
        >
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/dashboard/*" element={<AdminDashboard />} />
                        <Route path="/" element={<Login />} />
                    </Routes>
                </div>
            </Router>
        </ReactKeycloakProvider>
    );
};

export default App;
