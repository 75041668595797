import React, {useEffect, useState} from "react";
import {Button, Col, Row, Table, TableProps, Typography} from "antd";
import {authApi, getUserId} from "../../resources/authService";
import dayjs, {Dayjs} from "dayjs";
import ResultFinalModal from "./ResultFinalModal";
const { Title, Text } = Typography;

interface ResultFormProps {
    availableWidth: number;
}


interface DataType {
    id: number;
    date: Dayjs;
    no: number;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    total_methane_from_neural_network: number;
    storages: ResultStorage [];
}

interface ResultStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}

const ResultFinal : React.FC<ResultFormProps> = ({availableWidth}) => {
    const [data, setData] = useState<DataType[]>([]);
    const [selectedRowData, setSelectedRowData] = useState<DataType | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const companyName = getUserId();


    useEffect(() => {
        authApi.get(`result/todays?factoryName=${companyName}&date=${dayjs().format('YYYY-MM-DD')}`)
            .then(response => {
                const responseData = response.data;
                setData(responseData);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [companyName]);





    const columns: TableProps<DataType>['columns'] = [
        {
            title: <Text strong>No</Text>,
            dataIndex: 'no',
            key: 'no',
        }, {
            title: <Text strong>Date</Text>,
            dataIndex: 'date',
            key: 'date',
            render: (date:Dayjs) => dayjs(date).format('YYYY-MM-DD, HH:mm:ss'),
        }, {
            title: <Text strong>Quantity</Text>,
            dataIndex: 'total_quantity',
            key: 'total_quantity',
        }, {
            title: <Text strong>Price</Text>,
            dataIndex: 'total_price',
            key: 'total_price',
        }, {
            title: <Text strong>Methane</Text>,
            dataIndex: 'total_methane',
            key: 'total_methane',
        }, {
            title: <Text strong>Carbs</Text>,
            dataIndex: 'total_carbs',
            key: 'total_carbs',
        }, {
            title: <Text strong>Proteins</Text>,
            dataIndex: 'total_protein',
            key: 'total_protein',
        }, {
            title: <Text strong>Fat</Text>,
            dataIndex: 'total_fat',
            key: 'total_fat',
        }, {
            title: <Text strong>Neural Methane</Text>,
            dataIndex: 'total_methane_from_neural_network',
            key: 'total_methane_from_neural_network',
        } , {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: () => viewButton(), // Render viewButton for each row
        }
    ];

    const viewButton = () => (
        <Button onClick={() => handleView()}>
            View
        </Button>
    );

    const handleView = () => {
        console.log(selectedRowData);
        setModalVisible(true);
    }

    const onRow = (record: DataType) => {
        return {
            onClick: () => {
                setSelectedRowData(record);
                setSelectedRowData(record);
            },
        };
    };

    const handleModalClose = () => {
        setModalVisible(false); // Hide the modal
    }

    const submitChoose = () => {
        handleModalClose();
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: '50px',
                    flex: "auto",
                }}
            >
                <Row justify="center" align="middle" style={{width: '100%', marginBottom: '30px'}}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Title>Final Results</Title>
                        <Table style={{marginTop: '20px'}} dataSource={data} onRow={onRow} columns={columns} rowKey="id" scroll={{y: 305}}/>
                    </Col>
                </Row>
                <ResultFinalModal isOpen={modalVisible} onClose={handleModalClose} data={selectedRowData} resultSubmitChoose={submitChoose} availableWidth={availableWidth} />
            </div>
        </>
    )
}

export default ResultFinal;