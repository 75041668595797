// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sensors.module.css */

.Sensors_sensors-container__3MYCJ {
    /* Correct CSS properties with kebab-case */
    padding: 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: white;

}

/* For 720p resolution screens */
@media (max-width: 1300px) {
    .Sensors_sensors-container__3MYCJ {
        width: 100%;
    }
    .Sensors_yesterday-container__LihBL {
        margin-top: 1px;
    }
}

@media (min-width: 1301px) and (max-width: 1500px) {
    .Sensors_sensors-container__3MYCJ {
        width: 95%;
    }
    .Sensors_yesterday-container__LihBL {
        margin-top: 30px;
    }
}

/* For 1080p resolution screens */
@media (min-width: 1501px) {
    .Sensors_sensors-container__3MYCJ {
        width: 90%;
        margin-top: 50px;
        height: 40%;
    }
    .Sensors_yesterday-container__LihBL {
        margin-top: 60px;
    }
}



.Sensors_yesterday-container__LihBL {

}


#Sensors_gauge__D7bPv {
    height: 440px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/sensors/Sensors.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,2CAA2C;IAC3C,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;;AAE3B;;AAEA,gCAAgC;AAChC;IACI;QACI,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,gBAAgB;IACpB;AACJ;;AAEA,iCAAiC;AACjC;IACI;QACI,UAAU;QACV,gBAAgB;QAChB,WAAW;IACf;IACA;QACI,gBAAgB;IACpB;AACJ;;;;AAIA;;AAEA;;;AAGA;IACI,aAAa;IACb,WAAW;AACf","sourcesContent":["/* Sensors.module.css */\r\n\r\n.sensors-container {\r\n    /* Correct CSS properties with kebab-case */\r\n    padding: 40px;\r\n    border-radius: 8px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: white;\r\n\r\n}\r\n\r\n/* For 720p resolution screens */\r\n@media (max-width: 1300px) {\r\n    .sensors-container {\r\n        width: 100%;\r\n    }\r\n    .yesterday-container {\r\n        margin-top: 1px;\r\n    }\r\n}\r\n\r\n@media (min-width: 1301px) and (max-width: 1500px) {\r\n    .sensors-container {\r\n        width: 95%;\r\n    }\r\n    .yesterday-container {\r\n        margin-top: 30px;\r\n    }\r\n}\r\n\r\n/* For 1080p resolution screens */\r\n@media (min-width: 1501px) {\r\n    .sensors-container {\r\n        width: 90%;\r\n        margin-top: 50px;\r\n        height: 40%;\r\n    }\r\n    .yesterday-container {\r\n        margin-top: 60px;\r\n    }\r\n}\r\n\r\n\r\n\r\n.yesterday-container {\r\n\r\n}\r\n\r\n\r\n#gauge {\r\n    height: 440px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sensors-container": `Sensors_sensors-container__3MYCJ`,
	"yesterday-container": `Sensors_yesterday-container__LihBL`,
	"gauge": `Sensors_gauge__D7bPv`
};
export default ___CSS_LOADER_EXPORT___;
