import React, {useState} from "react";
import {Button, Row, Table, TableProps, Typography} from "antd";
import ResultSolutionsModal from "./ResultSolutionsModal";

const { Title, Text } = Typography;


interface ResultFormProps {
    onChoose: (id : number) => void;
    solutions: Solutions[];
    availableWidth: number;
}

interface Solutions {
    no: number;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    solution: SolutionStorage [];
}

interface SolutionStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}


const ResultSolutionsTable: React.FC<ResultFormProps> = ({onChoose, solutions, availableWidth}) => {
    const [selectedRowData, setSelectedRowData] = useState<Solutions | null>(null);
    const [modalVisible, setModalVisible] = useState(false);



    const columns: TableProps<Solutions>['columns'] = [
        {
            title: <Text strong>No</Text>,
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: <Text strong>Total Quantity</Text>,
            dataIndex: 'total_quantity',
            key: 'total_quantity',
        }, {
            title: <Text strong>Total Price</Text>,
            dataIndex: 'total_price',
            key: 'total_price',
        }, {
            title: <Text strong>Total Methane</Text>,
            dataIndex: 'total_methane',
            key: 'total_methane',
        }, {
            title: <Text strong>Total Carbs</Text>,
            dataIndex: 'total_carbs',
            key: 'total_carbs',
        }, {
            title: <Text strong>Total Protein</Text>,
            dataIndex: 'total_protein',
            key: 'total_protein',
        }, {
            title: <Text strong>Total Fat</Text>,
            dataIndex: 'total_fat',
            key: 'total_fat',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: () => viewButton(), // Render viewButton for each row
        }
    ];


    const viewButton = () => (
        <Button onClick={() => handleView()}>
            View
        </Button>
    );

    const handleView = () => {
        console.log(selectedRowData);
        setModalVisible(true);
    }

    const submitChoose = (id: number) => {
        onChoose(id);
    }

    const onRow = (record: Solutions) => {
        return {
            onClick: () => {
                setSelectedRowData(record);
                setSelectedRowData(record);
            },
        };
    };

    const handleModalClose = () => {
        setModalVisible(false); // Hide the modal
    }


    return (
        <>
            <Row justify="center" align="middle" style={{width: '100%', marginTop: '50px'}}>
                <Title>Choose Solution</Title>

                <Table style={{marginTop: '20px'}} dataSource={solutions} columns={columns} onRow={onRow} rowKey= "no"
                       scroll={{y: 325}}/>
            </Row>


            <ResultSolutionsModal isOpen={modalVisible} onClose={handleModalClose} data={selectedRowData} resultSubmitChoose={submitChoose} availableWidth={availableWidth} />
        </>
    )
}

export default ResultSolutionsTable;