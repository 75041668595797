import {Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Space} from "antd";
import dayjs, {Dayjs} from "dayjs";
import React, {useEffect} from "react";
import {authApi, getUserId} from "../../resources/authService";

interface AnalysesProps {
    isOpen: boolean;
    onClose: () => void; // Optional prop
    data?: AnalysesType | null;
    id?: number;
}

interface AnalysesType {
    name: string;
    type: string;
    date: Dayjs; // LocalDate is serialized to a string in JSON
    source: string;
    available_in_source: number;
    available_in_unit: number;
    distance: number;
    cost_of_purchase: number;
    cost: number;
    total_solids: number;
    volatile_solids: number;
    carbs: number;
    proteins: number;
    fat: number;
    nh4: number;
    volatile_fatty_acids: number;
    biogas_potential: number;
    methane_potential: number;
}

interface AnalysesTypeCreate {
    factoryName: string;
    name: string;
    type: string;
    date: Dayjs; // LocalDate is serialized to a string in JSON
    source: string;
    available_in_source: number;
    available_in_unit: number;
    distance: number;
    cost_of_purchase: number;
    cost: number;
    total_solids: number;
    volatile_solids: number;
    carbs: number;
    proteins: number;
    fat: number;
    nh4: number;
    volatile_fatty_acids: number;
    biogas_potential: number;
    methane_potential: number;
}

const AnalysModal: React.FC<AnalysesProps> = ({ isOpen, onClose, data , id}) => {
    const [form] = Form.useForm();
    const companyName = getUserId();


    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                id: id,
                name: data.name,
                type: data.type,
                date: data.date ? dayjs(data.date) : null,
                source: data.source,
                available_in_source: data.available_in_source,
                available_in_unit: data.available_in_unit,
                distance: data.distance,
                cost_of_purchase: data.cost_of_purchase,
                cost: data.cost,
                total_solids: data.total_solids,
                volatile_solids: data.volatile_solids,
                carbs: data.carbs,
                proteins: data.proteins,
                fat: data.fat,
                nh4: data.nh4,
                volatile_fatty_acids: data.volatile_fatty_acids,
                biogas_potential: data.biogas_potential,
                methane_potential: data.methane_potential,
            });
        }
        else {
            form.resetFields();
        }
    }, [data, form, id]);


    const handleSubmit = () => {
        form.validateFields().then((values) => {

            const {
                name,
                type,
                date,
                source,
                available_in_source,
                available_in_unit,
                distance,
                cost_of_purchase,
                cost,
                total_solids,
                volatile_solids,
                carbs,
                proteins,
                fat,
                nh4,
                volatile_fatty_acids,
                biogas_potential,
                methane_potential
            } = values;


            const utcDate = dayjs(date).utc(true).hour(12).minute(0).second(0).millisecond(0);

            const analysesType: AnalysesTypeCreate = {
                factoryName: companyName ? companyName : "",
                name: name,
                type: type,
                date: utcDate,
                source: source,
                available_in_source: available_in_source,
                available_in_unit:available_in_unit,
                distance: distance,
                cost_of_purchase: cost_of_purchase,
                cost: cost,
                total_solids: total_solids,
                volatile_solids: volatile_solids,
                carbs: carbs,
                proteins: proteins,
                fat: fat,
                nh4: nh4,
                volatile_fatty_acids: volatile_fatty_acids,
                biogas_potential: biogas_potential,
                methane_potential: methane_potential
            };

            const endpoint = data ? `analyses/update/${id}` : `analyses/create`;
            const method = data ? authApi.put : authApi.post;

            method(endpoint, analysesType)
                .then(response => {
                    console.log(response.data);
                    message.success('Operation successful');
                    form.resetFields();
                    onClose(); // Close the modal on success
                })
                .catch(error => {
                    console.error('Error:', error);
                    message.error('Operation failed');
                });



        });
    };

    const validateNumber = (_:any, value:any) => {
        if (value && isNaN(value)) {
            return Promise.reject(new Error('Please enter a valid number'));
        }
        else if (value && value.toString().includes('.')) {
            return Promise.reject(new Error('Please enter a valid integer'));
        }
        return Promise.resolve();
    };

    if (!isOpen) {
        return null;
    }

    const onDelete = () => {
        authApi.delete(`analyses/delete/${id}`)
            .then(() => {
                console.log(`Fertilizer with ID ${id} has been deleted.`);
                onClose();
                // Assuming you want to update the UI after deletion, you can filter out the deleted medicine from the list
            })
            .catch(error => {
                console.error(`Error deleting analyses with ID ${id}:`, error);
            });
        onClose();
    };

    return (
        <div>
            <Modal
                title={data ? "Edit Analyses" : "Add Analyses"}
                open={isOpen}
                onCancel={onClose}
                footer={null}
                width="40%"
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    style={{
                        marginTop: '20px',
                        width: '100%',
                    }}
                >
                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input the name!' }]}
                            >
                                <Input placeholder="Add name" disabled={!!data}/>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{ required: true, message: 'Please input the type!' }]}
                            >
                                <Input placeholder="Add type" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[{ required: true, message: 'Please select the date!' }]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Source"
                                name="source"
                                rules={[{ required: true, message: 'Please input the source!' }]}
                            >
                                <Input placeholder="Add source" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Available in Source (kg)"
                                name="available_in_source"
                                rules={[{ required: true, message: 'Please input the availability in source!' }, { validator: validateNumber }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add availability in source" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Available in Unit (kg)"
                                name="available_in_unit"
                                rules={[{ required: true, message: 'Please input the availability in unit!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add availability in unit" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Distance"
                                name="distance"
                                rules={[{ required: true, message: 'Please input the distance!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add distance" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Cost of Purchase"
                                name="cost_of_purchase"
                                rules={[{ required: true, message: 'Please input the cost of purchase!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add cost of purchase" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Cost"
                                name="cost"
                                rules={[{ required: true, message: 'Please input the cost!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add cost" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Total solids (kg)"
                                name="total_solids"
                                rules={[{ required: true, message: 'Please input the total solids!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add total solids" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Volatile solids (kg)"
                                name="volatile_solids"
                                rules={[{ required: true, message: 'Please input the volatile solids!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add volatile solids" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Carbs (%)"
                                name="carbs"
                                rules={[{ required: true, message: 'Please input the carbs!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add carbs" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Proteins (%)"
                                name="proteins"
                                rules={[{ required: true, message: 'Please input the proteins!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add proteins" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Fat (%)"
                                name="fat"
                                rules={[{ required: true, message: 'Please input the fat!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add fat" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Nh4 (mg/L)"
                                name="nh4"
                                rules={[{ required: true, message: 'Please input the nh4!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add nh4" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Volatile fatty acids (mg/L)"
                                name="volatile_fatty_acids"
                                rules={[{ required: true, message: 'Please input the volatile fatty acids!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add volatile fatty acids" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[48, 16]}>

                        <Col span={12} >
                            <Form.Item
                                label="Biogas Potential (L/kgVS)"
                                name="biogas_potential"
                                rules={[{ required: true, message: 'Please input the biogas potential!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add biogas potential" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="Methane Potential (L/kgVS)"
                                name="methane_potential"
                                rules={[{ required: true, message: 'Please input the methane potential!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Add methane potential" />
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>





                <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                    <Space size="large">
                        <Button onClick={handleSubmit} type="primary"> {data ? "Edit" :"Add"} </Button>

                        {data ?

                            <Button type="primary" onClick={onDelete} danger>Delete</Button>

                            :
                            null
                        }

                        <Button onClick={onClose} danger>Cancel</Button>
                    </Space>
                </div>
            </Modal>
        </div>
    );
};

export default AnalysModal;