import {authApi} from "./authService";
import {keycloak} from "./keycloak";



export const getName = (): string => {

        const userId = keycloak.tokenParsed?.sub ?? "";

        authApi.get(`factory_details/getName/${userId}`)
            .then(response => {
                // Assuming the response data has the same structure as your form
                const data = response.data;

                if(data.location === "" || data.location===undefined || data.location===null) {

                    localStorage.setItem('companyName', data.name);
                    return data.name;

                } else {

                    localStorage.setItem('companyName', data.name);
                    return data.name;

                }

            })
            .catch(error => {
                console.error('Error fetching factory name:', error);
            });

    return "";
};