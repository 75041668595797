import React, {useEffect} from 'react';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    InfoCircleOutlined,
    FileOutlined,
    FolderOutlined,
    DashboardOutlined, BookOutlined,
} from '@ant-design/icons';
import {Button, Image, Col, Layout, Menu, MenuProps, Row, theme} from 'antd';
import styles from "./AdminDashboard.module.css";

import {useLocation, useNavigate} from "react-router-dom";
import FactoryDetails from "../pages/factory_details/FactoryDetails";
import Sensors from "../pages/sensors/Sensors";
import Analyses from "../pages/analyses/Analyses";
import Supply from "../pages/supply/Supply";
import Result from "../pages/result/Result";
import {onLogout, validate} from "../resources/authService";
import Saved from "../pages/saved/Saved";


const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}



const items: MenuProps['items'] = [

    getItem('System Info', 'system', <FolderOutlined />, [
        getItem('Biogas Unit info', 'factory_details', <InfoCircleOutlined />),
        getItem('Sensors Info', 'sensors', <DashboardOutlined />),
    ]),

    getItem('Analyses', 'analyses', <FileOutlined />),

    getItem('Supply', 'supply', <FileOutlined />),

    getItem('Result', 'result', <FileOutlined />),

    getItem('Saved', 'saved', <BookOutlined />),

];

function AdminDashboard () {
    const [collapsed, setCollapsed] = React.useState(false);
    const location = useLocation();
    const isFactoryDetailsPath = location.pathname === '/dashboard/factory_details' || location.pathname === '/dashboard';
    const isSensorsPath = location.pathname === '/dashboard/sensors';
    const isAnalysesPath = location.pathname === '/dashboard/analyses';
    const isSupplyPath = location.pathname === '/dashboard/supply';
    const isResultPath = location.pathname === '/dashboard/result';
    const isSavedPath = location.pathname === '/dashboard/saved';
    const navigate = useNavigate();

    // Redirect to login if companyName is not set


    useEffect(() => {
        const init = async () => {
            if (! await validate()){
                navigate('/');
            }
        };

        init();
    });


    const {
        token: { colorBgContainer },
    } = theme.useToken();



    const handleMenuClick = (key: string) => {
        // Implement your navigation logic based on the key here
        switch (key) {
            case 'factory_details':
                navigate('/dashboard/factory_details');
                console.log("nav")
                break;

            case 'sensors':
                navigate('/dashboard/sensors');
                console.log("nav")
                break;

            case 'analyses':
                navigate('/dashboard/analyses');
                console.log("nav")
                break;

            case 'result':
                navigate('/dashboard/result');
                console.log("nav")
                break;

            case 'supply':
                navigate('/dashboard/supply');
                console.log("nav")
                break;

            case 'saved':
                navigate('/dashboard/saved');
                console.log("nav")
                break;
            // Add cases for other menu options
            default:
                break;
        }
    };

    const handleAppClose = async () => {
        localStorage.removeItem('companyName');
        await onLogout ();
    }

    return (
        <div style={{ display: 'flex', height: '100vh', fontSize: '24px' }}>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: colorBgContainer, height: '100%'}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Image
                            className={styles.logo}
                            style={{ margin: '28px' }}
                            src="/logo.jpeg"
                        />                    </div>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={ isSensorsPath ? ['sensors'] : isAnalysesPath ? ['analyses'] : isSupplyPath ? ['supply'] : isResultPath ? ['result'] : ['factory_details']}
                        defaultOpenKeys={['system']}
                        items={items}
                        style={{fontSize: '16px'}}
                        onClick={(e) => handleMenuClick(e.key as string)}
                    />
                </Sider>
                <Layout >
                    <Header style={{ padding: 0, background: colorBgContainer }}>
                        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                            <Col>
                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: '24px',
                                        width: 64,
                                        height: 64,
                                    }}
                                />
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
                                <div style={{ marginRight: '30px' }}>
                                    <Button type="primary" danger onClick={handleAppClose}>Logout</Button>
                                </div>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        style={{
                            padding: 24,
                            minHeight: 280,
                            height: '100%',
                            backgroundColor: '#f0f2f5', // or any other gray color you prefer
                            overflowY: 'auto', // Enable vertical scrolling

                        }}
                    >

                        {isFactoryDetailsPath ? <FactoryDetails /> : null}
                        {isSensorsPath ? <Sensors collapsed={collapsed} /> : null}
                        {isAnalysesPath ? <Analyses/> : null}
                        {isSupplyPath ? <Supply/> : null}
                        {isResultPath ? <Result collapsed={collapsed} /> : null}
                        {isSavedPath ? <Saved collapsed={collapsed} /> : null}

                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default AdminDashboard;