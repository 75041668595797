import React, {useCallback, useEffect, useState} from 'react';
import styles from "../sensors/Sensors.module.css";
import {Button, Card, Col, DatePicker, Form, InputNumber, message, Popover, Row, Typography} from "antd";
// @ts-ignore
import {
    CircularGauge, Scale, Label, RangeContainer, Range, Title, Font, ValueIndicator,
} from 'devextreme-react/circular-gauge';
import {authApi, getUserId} from "../../resources/authService";
import dayjs, {Dayjs} from "dayjs";



function customizeText({ valueText }: { valueText: string }) {
    return `${valueText}`;
}

interface SensorsProps {
    collapsed: boolean;  // Replace 'string' with the appropriate type for your variable
}

interface Sensor_Edit {
    ph: number;
    redox: number;
    temperature: number;
    nh4: number;
    biogas: number;
    methane: number;
    propionic: number;
    butyric: number;
    valeric: number;
    acetic: number;
    ammonia: number;
}


function Sensors ({ collapsed }: SensorsProps) {
    const [sensorData, setSensorData] = useState({
        ph: 0,
        redox: 0,
        temperature: 0,
        nh4: 0,
    });
    const [sensorYesterday, setYesterday] = useState({
        ph: 0,
        redox: 0,
        temperature: 0,
        nh4: 0,
        biogas: 0,
        methane: 0,
        propionic: 0,
        butyric: 0,
        valeric: 0,
        acetic: 0,
        ammonia: 0
    });
    const companyName = getUserId();
    const siderWidthCollapsed = 80;
    const siderWidthExpanded = 200;
    // Calculate available width based on the collapsed state
    const availableWidth = window.innerWidth - (collapsed ? siderWidthCollapsed : siderWidthExpanded);
    // Calculate the width for each gauge based on the number of items per row and the available width
    const gaugeWidth = availableWidth / 4; // Assuming there are 4 gauges per row
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useState<string>(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    const dateFormat = 'YYYY-MM-DD';




    const fetchLatestSensorData = useCallback(() => {
        authApi.get(`sensors/result/latest?factoryName=${companyName}`)
            .then(response => {
                setSensorData(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [companyName]);

    // Function to fetch yesterday's sensor data
    const fetchYesterdaySensorData = useCallback(() => {

        console.log(searchParams)

        authApi.get(`sensors/result/average?factoryName=${companyName}&date=${dayjs(searchParams).format('YYYY-MM-DD')}`)
            .then(response => {
                console.log(response.data)
                setYesterday(response.data);
                form.resetFields()
                form.setFieldsValue({
                    ph: response.data.ph,
                    redox: response.data.redox,
                    // Convert dates to Dayjs objects if necessary
                    temperature: response.data.temperature,
                    nh4: response.data.nh4,
                    biogas: response.data.biogas,
                    methane: response.data.methane,
                    T_propionic: response.data.T_propionic,
                    T_butyric: response.data.T_butyric,
                    T_valeric: response.data.T_valeric,
                    T_acetic: response.data.T_acetic,
                    T_ammonia: response.data.T_ammonia
                });
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

    }, [companyName, searchParams, form]);


    useEffect(() => {
        // Fetch the initial data
        fetchLatestSensorData();
        fetchYesterdaySensorData();

        console.log("here")

        // Set an interval to refresh data every 20 seconds
        const interval = setInterval(() => {
            fetchLatestSensorData();
            // Optionally, you can also refresh yesterday's data if needed
            // fetchYesterdaySensorData();
        }, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);

    }, [searchParams, collapsed, fetchLatestSensorData, fetchYesterdaySensorData]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {

            const {
                ph,
                redox,
                // Convert dates to Dayjs objects if necessary
                temperature,
                nh4,
                biogas,
                methane,
                propionic,
                butyric,
                valeric,
                acetic,
                ammonia,
            } = values;



            const sensorEdit: Sensor_Edit = {
                ph : ph ?  ph : sensorYesterday.ph,
                redox : redox ?  redox : sensorYesterday.redox,
                // Convert dates to Dayjs objects if necessary
                temperature : temperature ?  temperature : sensorYesterday.temperature,
                nh4 : nh4 ?  nh4 : sensorYesterday.nh4,
                biogas : biogas ?  biogas : sensorYesterday.biogas,
                methane : methane ?  methane : sensorYesterday.methane,
                propionic: propionic ?  propionic : sensorYesterday.propionic,
                butyric: butyric ?  butyric : sensorYesterday.butyric,
                valeric: valeric ?  valeric : sensorYesterday.valeric,
                acetic: acetic ?  acetic : sensorYesterday.acetic,
                ammonia: ammonia ?  ammonia : sensorYesterday.ammonia
            };


            console.log(sensorEdit);


            authApi.put(`sensors/result/average/edit?factoryName=${companyName}&date=${dayjs(searchParams).format('YYYY-MM-DD')}`, sensorEdit)
                .then(response => {
                    console.log(response.data);
                    setYesterday(response.data);
                    form.setFieldsValue({
                        ph: response.data.ph,
                        redox: response.data.redox,
                        // Convert dates to Dayjs objects if necessary
                        temperature: response.data.temperature,
                        nh4: response.data.nh4,
                        biogas: response.data.biogas,
                        methane: response.data.methane,
                        T_propionic: response.data.T_propionic,
                        T_butyric: response.data.T_butyric,
                        T_valeric: response.data.T_valeric,
                        T_acetic: response.data.T_acetic,
                        T_ammonia: response.data.T_ammonia
                    });
                    //message.success(response.data);
                    // You can navigate or perform other actions after successful POST
                })
                .catch(error => {
                    console.error('Error:', error.error);
                    message.error('Server error');
                });

        }).catch(() => {
            console.error('Error: Wrong field values');
            message.error('Error: Wrong field values');
        });
    };


    const handleDateChange = (date: Dayjs | null, dateString: string) => {
        setSearchParams(dateString);
    };

    return(
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}
        >
            <div className={styles['sensors-container']}>
                <Row gutter={[30, 16]} justify="center">
                    <Col span={6} style={{ width: `${gaugeWidth}px`, height: '360px' }}> {/* Adjust the size here */}
                        <CircularGauge
                            id="temperature"
                            value={sensorData.temperature}
                        >
                            <Scale startValue={-20} endValue={100} tickInterval={10}>
                                <Label customizeText={customizeText} />
                            </Scale>
                            <RangeContainer>
                                <Range startValue={-20} endValue={0} color="#CE2029" />
                                <Range startValue={0} endValue={20} color="#FFD700" />
                                <Range startValue={20} endValue={50} color="#228B22" />
                                <Range startValue={50} endValue={80} color="#FFD700" />
                                <Range startValue={80} endValue={100} color="#CE2029" />
                            </RangeContainer>
                            <ValueIndicator color="#03a9f4" />
                            <Title text="Temperature">
                                <Font size={28} />
                            </Title>
                        </CircularGauge>
                        <Popover content={sensorData.temperature}>
                            <Button type="dashed">Value</Button>
                        </Popover>
                    </Col>
                    <Col span={6} style={{ width: `${gaugeWidth}px`, height: '360px' }}> {/* Adjust the size here */}
                        <CircularGauge
                            id="ph"
                            value={sensorData.ph}
                        >
                            <Scale startValue={0} endValue={14} tickInterval={1}>
                                <Label customizeText={customizeText} />
                            </Scale>
                            <RangeContainer>
                                <Range startValue={0} endValue={4} color="#CE2029" />
                                <Range startValue={4} endValue={5} color="#FFD700" />
                                <Range startValue={5} endValue={10} color="#228B22" />
                                <Range startValue={10} endValue={13} color="#FFD700" />
                                <Range startValue={13} endValue={14} color="#CE2029" />
                            </RangeContainer>
                            <ValueIndicator color="#03a9f4" />
                            <Title text="Ph">
                                <Font size={28} />
                            </Title>
                        </CircularGauge>
                        <Popover content={sensorData.ph}>
                            <Button type="dashed">Value</Button>
                        </Popover>
                    </Col>
                    <Col span={6} style={{ width: `${gaugeWidth}px`, height: '360px' }}> {/* Adjust the size here */}
                        <CircularGauge
                            id="redox"
                            value={sensorData.redox}
                        >
                            <Scale startValue={0} endValue={400} tickInterval={50}>
                                <Label customizeText={customizeText} />
                            </Scale>
                            <RangeContainer>
                                <Range startValue={0} endValue={100} color="#CE2029" />
                                <Range startValue={100} endValue={200} color="#FFD700" />
                                <Range startValue={200} endValue={400} color="#228B22" />
                            </RangeContainer>
                            <ValueIndicator color="#03a9f4" />
                            <Title text="Redox">
                                <Font size={28} />
                            </Title>
                        </CircularGauge>
                        <Popover content={sensorData.redox}>
                            <Button type="dashed">Value</Button>
                        </Popover>
                    </Col>
                    <Col span={6} style={{ width: `${gaugeWidth}px`, height: '360px' }}> {/* Adjust the size here */}

                        <CircularGauge
                            id="nh4"
                            value={sensorData.nh4}
                        >
                            <Scale startValue={0} endValue={100} tickInterval={20}>
                                <Label customizeText={customizeText} />
                            </Scale>
                            <RangeContainer>
                                <Range startValue={0} endValue={20} color="#CE2029" />
                                <Range startValue={20} endValue={50} color="#FFD700" />
                                <Range startValue={50} endValue={100} color="#228B22" />
                            </RangeContainer>
                            <ValueIndicator color="#03a9f4" />
                            <Title text="NH4">
                                <Font size={28} />
                            </Title>
                        </CircularGauge>
                        <Popover content={sensorData.nh4}>
                            <Button type="dashed">Value</Button>
                        </Popover>
                    </Col>
                </Row>

                <div className={styles['yesterday-container']}>
                    <Typography.Title level={2} >Averages</Typography.Title>
                    <DatePicker onChange={(date, dateString) => handleDateChange(date, dateString as string)} format={dateFormat} defaultValue={dayjs().subtract(1, 'day')} />

                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        style={{
                            marginTop: '20px',
                            width: '100%'
                        }}>
                        <Row gutter={[16, 16]} style={{marginTop: '40px'}}>
                            <Col span={4}>
                                <Form.Item
                                    name="temperature"
                                >

                                    <Card title="Temperature" type="inner" bordered={true} style={{fontSize: '24px'}}>

                                        {sensorYesterday.temperature}°C
                                        <InputNumber placeholder="Add average temperature" min={0} style={{width: '100%'}}/>

                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="ph"
                                >

                                    <Card title="Ph" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.ph}
                                        <InputNumber placeholder="Add average ph" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="redox"
                                >

                                    <Card title="Redox - mV" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.redox}
                                        <InputNumber placeholder="Add average redox" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="nh4"
                                >

                                    <Card title="Nh4 - mg/L" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.nh4}
                                        <InputNumber placeholder="Add average nh4" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="biogas"
                                >

                                    <Card title="Biogas - L/d" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.biogas}
                                        <InputNumber placeholder="Add average biogas" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4} >
                                <Form.Item
                                    name="methane"
                                >

                                    <Card title="Methane - % of biogas" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.methane}%
                                        <InputNumber placeholder="Add average methane" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                        </Row>


                        <Row gutter={[16, 16]} style={{marginTop: '40px', display: "flex",
                            justifyContent: "center",
                            alignItems: "center"}} >
                            <Col span={4}>
                                <Form.Item
                                    name="propionic"
                                >

                                    <Card title="Propionic" type="inner" bordered={true} style={{fontSize: '24px'}}>

                                        {sensorYesterday.propionic}
                                        <InputNumber placeholder="Add propionic" min={0} style={{width: '100%'}}/>

                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="butyric"
                                >

                                    <Card title="Butyric" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.butyric}
                                        <InputNumber placeholder="Add butyric" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="valeric"
                                >

                                    <Card title="Valeric" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.valeric}
                                        <InputNumber placeholder="Add valeric" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="acetic"
                                >

                                    <Card title="Acetic" type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.acetic}
                                        <InputNumber placeholder="Add acetic" min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="ammonia"
                                >

                                    <Card title="Ammonia " type="inner" bordered={true} style={{fontSize: '24px'}}>
                                        {sensorYesterday.ammonia}
                                        <InputNumber placeholder="Add ammonia " min={0} style={{width: '100%'}}/>
                                    </Card>

                                </Form.Item>

                            </Col>

                        </Row>

                        <Button type="primary" onClick={handleSubmit} size={"large"} style={{width: '20%'}}>
                            Submit
                        </Button>
                    </Form>

                </div>
            </div>
        </div>

    );
}

export default Sensors;