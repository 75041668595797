import React, {useEffect} from "react";
import {Button, Descriptions, Divider, List, message, Modal, Space} from "antd";
import {authApi, getUserId} from "../../resources/authService";


interface ResultSolutionsProps {
    isOpen: boolean;
    onClose: () => void; // Optional prop
    data?: Solutions | null;
    resultSubmitChoose: (id : number) => void;
    availableWidth:number;
}

interface Solutions {
    no: number;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    solution: SolutionStorage [];
}

interface SolutionStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}

interface ResultDtoCreate {
    factoryName: string;
    quantity: number;
    price: number;
    methane: number;
    carbs: number;
    protein: number;
    fat: number;
    storages: StorageResultDtoCreate[];
}

interface StorageResultDtoCreate {
    analysesId: number;
    weight: number;
}

const ResultSolutionsModal: React.FC<ResultSolutionsProps> = ({ isOpen, onClose, data, resultSubmitChoose, availableWidth}) => {
    const companyName = getUserId();

    useEffect(() => {
        console.log(data)
        console.log(data?.solution)
    }, [data]);


    const submitChoose = () => {

        const endpoint = `result/create`;
        const method = authApi.post;


        console.log(data);

        if (data !== undefined && data !== null) {
            const resultDtoCreate: ResultDtoCreate = {
                factoryName: companyName ? companyName : "",
                quantity: data.total_quantity,
                price: data.total_price,
                methane: data.total_methane,
                carbs: data.total_carbs,
                protein: data.total_protein,
                fat: data.total_fat,

                storages: data?.solution ? data.solution.map(sol => ({
                    analysesId: sol.analysesId,
                    weight: sol.quantity
                })) : []
            };

            console.log(resultDtoCreate)

            method(endpoint, resultDtoCreate).then(data => {

                resultSubmitChoose(data.data);
            }).catch(() => {
                message.error('Operation failed');
            });
        }



    }

    return (
        <>
            <Modal
                title={"Solution Details"}
                open={isOpen}
                onCancel={onClose}
                footer={null}
                width={availableWidth < 1200 ? "55%" : "40%"}>
                {data ? (
                    <>
                        <Descriptions title="Summary" bordered>
                            <Descriptions.Item label="No">{data.no}</Descriptions.Item>
                            <Descriptions.Item label="Total Quantity">{data.total_quantity}</Descriptions.Item>
                            <Descriptions.Item label="Total Price">{data.total_price}</Descriptions.Item>
                            <Descriptions.Item label="Total Methane">{data.total_methane}</Descriptions.Item>
                            <Descriptions.Item label="Total Carbs">{data.total_carbs}</Descriptions.Item>
                            <Descriptions.Item label="Total Protein">{data.total_protein}</Descriptions.Item>
                            <Descriptions.Item label="Total Fat">{data.total_fat}</Descriptions.Item>
                        </Descriptions>
                        <Divider />
                        <List
                            header={<div>Composition</div>}
                            bordered
                            dataSource={data.solution}
                            renderItem={(item) => (
                                <List.Item>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="Analyses Name">{item.analysesName}</Descriptions.Item>
                                        <Descriptions.Item label="Quantity">{item.quantity}</Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />

                        <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                            <Space>
                                <Button type={"primary"} onClick={() => submitChoose()}>
                                    Choose
                                </Button>
                                <Button type={"dashed"} danger onClick={() => onClose()}>
                                    Close
                                </Button>
                            </Space>
                        </div>

                    </>
                ) : (
                    <p>No data available</p>
                )}
            </Modal>
        </>
    )
}

export default ResultSolutionsModal;