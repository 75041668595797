
import {Button, Row, Space, Steps} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import ResultForm from "../../components/result/ResultForm";
import ResultNeural from "../../components/result/ResultNeural";
import ResultSolutionsTable from "../../components/result/ResultSolutionsTable";
import ResultFinal from "../../components/result/ResultFinal";



interface Solutions {
    no: number;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    solution: SolutionStorage [];
}

interface SolutionStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}

interface ResProps {
    collapsed: boolean;  // Replace 'string' with the appropriate type for your variable
}

function Result ({ collapsed }: ResProps) {
    const [data, setData] = useState<Solutions[]>([]);
    const siderWidthCollapsed = 80;
    const siderWidthExpanded = 200;
    // Calculate available width based on the collapsed state
    const availableWidth = window.innerWidth - (collapsed ? siderWidthCollapsed : siderWidthExpanded);
    const [stepStatus, setStepStatus] = useState<number>(0);
    const [isSubmit, setSubmit] = useState(false);
    const [id, setId] = useState<number>(-1);

    const handleOnChange = (current: number) => {
        setStepStatus(current);
        console.log(stepStatus);
    }

    const handleOnChoose = (id : number) => {
        setSubmit(true);
        setId(id);
        setStepStatus(stepStatus+1)
    }


    const getDataWithoutNeural = useCallback((solutions: Solutions[]) => {
        console.log(solutions)
        setStepStatus(stepStatus+1)
        setData(solutions);
    }, [stepStatus]);





    useEffect(() => {

    }, [availableWidth]);


    const onNext = () => {
        if (stepStatus>2)
            setStepStatus(0);
        else
            setStepStatus(stepStatus+1)
    }

    const onPrevious = () => {
        if (stepStatus<1)
            setStepStatus(3);
        else
            setStepStatus(stepStatus-1)
    }


    return (
        <div style={{padding: '40px', marginTop: '20px'}}>
            <Row>
                <Steps
                    current={stepStatus}
                    onChange={handleOnChange}
                    items={[
                        {
                            title: 'Choose Analyses',
                        },
                        {
                            title: 'Choose Solution',
                        },
                        {
                            title: 'Result',
                        },
                        {
                            title: 'Final Results',
                        },
                    ]}
                    style={{padding: '20px', maxWidth: '100%'}}
                />
                {stepStatus===0 ? <ResultForm onSubmit={getDataWithoutNeural}/> : undefined}
                {stepStatus===1 ? <ResultSolutionsTable onChoose={handleOnChoose} solutions={data} availableWidth={availableWidth}/> : undefined}
                {stepStatus===2 ? <ResultNeural isSubmit={isSubmit} id={id}/> : undefined}
                {stepStatus===3 ? <ResultFinal availableWidth={availableWidth}/> : undefined}

            </Row>

            <Row justify="center">
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Space>
                        <Button disabled={stepStatus < 1} onClick={onPrevious}>
                            Previous
                        </Button>
                        <Button disabled={stepStatus > 2} onClick={onNext}>
                            Next
                        </Button>
                    </Space>
                </div>
            </Row>
        </div>
    );
}

export default Result;
