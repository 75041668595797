import { keycloak } from './keycloak';
import axios from 'axios';
import {message} from "antd";
import {getName} from "./name";
// @ts-ignore
import Keycloak from 'keycloak-js';

export const onLogout = async () => {
    try {
        await keycloak.logout();
        await delay(3000);
        // Handle the successful login case, if necessary
    } catch (error) {
        console.error('Login failed', error);
        // Handle the login error case
    }
};

export const validate = async (): Promise<boolean> => {
    console.log(keycloak.authenticated)
    refreshToken();
    if (!keycloak.authenticated) {
        try {
            await waitForAuth(keycloak, 500);
        } catch (error) {
            if (localStorage.getItem('companyName')!== undefined &&
                localStorage.getItem('companyName') !== null &&
                localStorage.getItem('companyName') !== "")
                message.error(`Authentication failed`);

        }
    }
    return keycloak.authenticated || false;
};





export const waitForAuth = (keycloak : Keycloak, timeout: number) => {
    return new Promise((resolve, reject) => {
        const intervalTime = 100; // Check every 100 ms
        let elapsedTime = 0;

        const interval = setInterval(() => {
            if (keycloak.authenticated) {
                clearInterval(interval);
                resolve(true);
                return;
            } else if (elapsedTime >= timeout) {
                clearInterval(interval);
                reject(new Error('Authentication timeout'));
            } else {
                console.log(elapsedTime)
                elapsedTime += intervalTime;
            }
        }, intervalTime);
    });
};

const uri = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "smartnh4.duckdns.org";

// Authenticated API instance
export const authApi = axios.create({
    baseURL: `https://${uri}/api/v1/`, // Adjust this to your backend's base URL
});

// Interceptor to inject the Bearer token into headers for the authenticated instance
authApi.interceptors.request.use(async (config) => {
    // Check if the token is valid and refresh if necessary
    await keycloak.updateToken(5);

    // Include the token in the Authorization header
    config.headers.Authorization = `Bearer ${keycloak.token}`;

    return config;
}, (error) => {
    return Promise.reject(error);
});

export function delay(time: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, time));
}

export const getUserId = (): string => {

    if (keycloak.authenticated && keycloak.tokenParsed) {
        const username = localStorage.getItem('companyName');
        console.log("Username:", username);
        return username !== null ? username : "";
    } else {
        return getName();
    }
}


function refreshToken() {
    // Set the minValidity parameter to a desired threshold (in seconds)
    const minValidity = 30; // Refresh if the token expires within the next 30 seconds

    keycloak.updateToken(minValidity).then((refreshed: boolean)  => {
        if (refreshed) {
            console.log('Token was successfully refreshed');
        } else {
            console.log('Token is still valid');
        }
    }).catch((error: any) => {
        console.error('Failed to refresh the token, or the session has expired:', error);
    });
}
