import dayjs, {Dayjs} from "dayjs";
import React from "react";
import {Button, Descriptions, Divider, List, Modal, Space} from "antd";


interface ResultSolutionsProps {
    isOpen: boolean;
    onClose: () => void; // Optional prop
    data?: DataType | null;
    availableWidth:number;
}

interface DataType {
    id: number;
    date: Dayjs;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    total_methane_from_neural_network: number;
    storages: ResultStorage [];
}

interface ResultStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}


const SavedModal: React.FC<ResultSolutionsProps> = ({ isOpen, onClose, data, availableWidth}) => {
    return (
        <>
            <Modal
                title={"Saved Details"}
                open={isOpen}
                onCancel={onClose}
                footer={null}
                width={availableWidth < 1200 ? "55%" : "40%"}
            >
                {data ? (
                    <>
                        <Descriptions bordered>
                            <Descriptions.Item label="Date">
                                {dayjs(data.date).format('YYYY-MM-DD')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Total Quantity">{data.total_quantity}</Descriptions.Item>
                            <Descriptions.Item label="Total Price">{data.total_price}</Descriptions.Item>
                            <Descriptions.Item label="Total Methane">{data.total_methane}</Descriptions.Item>
                            <Descriptions.Item label="Total Carbs">{data.total_carbs}</Descriptions.Item>
                            <Descriptions.Item label="Total Protein">{data.total_protein}</Descriptions.Item>
                            <Descriptions.Item label="Total Fat">{data.total_fat}</Descriptions.Item>
                        </Descriptions>
                        <Divider />
                        <List
                            header={<div>Composition</div>}
                            bordered
                            dataSource={data.storages}
                            renderItem={(item) => (
                                <List.Item>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="Analyses Name">{item.analysesName}</Descriptions.Item>
                                        <Descriptions.Item label="Quantity">{item.quantity}</Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />

                        <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                            <Space>
                                <Button type={"dashed"} danger onClick={() => onClose()}>
                                    Close
                                </Button>
                            </Space>
                        </div>

                    </>
                ) : (
                    <p>No data available</p>
                )}
            </Modal>
        </>
    )
}

export default SavedModal;