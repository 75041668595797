import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import {waitForAuth} from "../../resources/authService";

const Login: React.FC = () => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            waitForAuth(keycloak, 5000).then(r => {
                if (keycloak.authenticated)
                    navigate('/dashboard');
                else
                    keycloak.login().catch((loginError : any) => {
                        message.error(`Login failed: ${loginError}`);
                    });
            });

        } catch (error) {
            message.error(`Authentication failed`);
            keycloak.login().catch((loginError : any) => {
                message.error(`Login failed: ${loginError}`);
            });
        }
    }, [keycloak, navigate]);

    return <div>Redirecting to login...</div>;
};

export default Login;
