// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminDashboard_logo__3iwes {
    margin-left: 15px;
    max-width: 80px; /* Adjust the value as per your requirements */
    height: auto;
    border-radius: 50%; /* Make the logo round */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}`, "",{"version":3,"sources":["webpack://./src/layout/AdminDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe,EAAE,8CAA8C;IAC/D,YAAY;IACZ,kBAAkB,EAAE,wBAAwB;IAC5C,wCAAwC,EAAE,wBAAwB;AACtE","sourcesContent":[".logo {\r\n    margin-left: 15px;\r\n    max-width: 80px; /* Adjust the value as per your requirements */\r\n    height: auto;\r\n    border-radius: 50%; /* Make the logo round */\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow effect */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `AdminDashboard_logo__3iwes`
};
export default ___CSS_LOADER_EXPORT___;
