import React from "react";
import {Dayjs} from "dayjs";
import {Button, Descriptions, Divider, List, message, Modal, Popconfirm, Space} from "antd";
import {authApi, getUserId} from "../../resources/authService";
import {QuestionCircleOutlined} from "@ant-design/icons";

interface ResultSolutionsProps {
    isOpen: boolean;
    onClose: () => void; // Optional prop
    data?: DataType | null;
    resultSubmitChoose: () => void;
    availableWidth:number;
}

interface DataType {
    id: number;
    date: Dayjs;
    no: number;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    total_methane_from_neural_network: number;
    storages: ResultStorage [];
}

interface ResultStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}


const ResultFinalModal: React.FC<ResultSolutionsProps> = ({ isOpen, onClose, data, resultSubmitChoose, availableWidth}) => {
    const companyName = getUserId();

    const submitChoose = (forced: boolean) => {
        let endpoint = `saved/save?id=${data?.id}`;
        if (forced)
            endpoint = `saved/forceSave?factoryName=${companyName}&id=${data?.id}`;

        const method = authApi.post;


        console.log(data?.id);

        method(endpoint).then(() => {
            message.success('Operation successful!');
            resultSubmitChoose();
        }).catch(reason => {
            console.log(reason)
            forced ? message.error(reason.response.data.message) : message.error(reason.response.data);
        });

    }

    return (
        <>
            <Modal
                title={"Result Details"}
                open={isOpen}
                onCancel={onClose}
                footer={null}
                width={availableWidth < 1200 ? "55%" : "40%"}
            >
                {data ? (
                    <>
                        <Descriptions title="Summary" bordered>
                            <Descriptions.Item label="No">{data.no}</Descriptions.Item>
                            <Descriptions.Item label="Total Quantity">{data.total_quantity}</Descriptions.Item>
                            <Descriptions.Item label="Total Price">{data.total_price}</Descriptions.Item>
                            <Descriptions.Item label="Total Methane">{data.total_methane}</Descriptions.Item>
                            <Descriptions.Item label="Total Carbs">{data.total_carbs}</Descriptions.Item>
                            <Descriptions.Item label="Total Protein">{data.total_protein}</Descriptions.Item>
                            <Descriptions.Item label="Total Fat">{data.total_fat}</Descriptions.Item>
                        </Descriptions>
                        <Divider />
                        <List
                            header={<div>Composition</div>}
                            bordered
                            dataSource={data.storages}
                            renderItem={(item) => (
                                <List.Item>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="Analyses Name">{item.analysesName}</Descriptions.Item>
                                        <Descriptions.Item label="Quantity">{item.quantity}</Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />

                        <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                            <Space>
                                <Button type={"primary"} onClick={() => submitChoose(false)}>
                                    Save
                                </Button>
                                <Popconfirm
                                    title="Force save"
                                    description={
                                        <>
                                            Are you sure to force save this entry?<br />
                                            It will delete the previous entry.
                                        </>
                                    }
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => submitChoose(true)}
                                    style={{display: 'flex', justifyContent: 'center'}}
                                >
                                    <Button type={"primary"} danger>
                                        Force Save
                                    </Button>
                                </Popconfirm>
                                <Button type={"dashed"} danger onClick={() => onClose()}>
                                    Close
                                </Button>
                            </Space>
                        </div>

                    </>
                ) : (
                    <p>No data available</p>
                )}
            </Modal>

        </>
    );
}

export default ResultFinalModal;