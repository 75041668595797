import React, {useEffect, useState} from "react";
import {Button, Col, Form, message, Row, Select, Space} from "antd";
import dayjs from "dayjs";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {authApi, getUserId} from "../../resources/authService";

interface ResultFormProps {
    onSubmit: (solutions: Solutions[]) => void;
}

interface Solutions {
    no: number;
    total_quantity: number;
    total_price: number;
    total_methane: number;
    total_carbs: number;
    total_protein: number;
    total_fat: number;
    solution: SolutionStorage [];
}

interface SolutionStorage {
    analysesId: number;
    analysesName: string;
    quantity: number;
}

interface ResultDtoCreate {
    factoryName: string;
    storages: number[];
}

interface Analysis {
    id: number;
    name: string;
    // Include other properties as necessary
}

const ResultForm: React.FC<ResultFormProps> = ({onSubmit}) => {
    const [form] = Form.useForm();
    const [analysesOptions, setAnalysesOptions] = useState<Analysis[]>([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to track if the submit button should be disabled
    const uri = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "localhost:8080";
    const companyName = getUserId();



    useEffect(() => {

        const init = async () => {
            authApi.get(`analyses/getAllForSupply?factoryName=${companyName}`).then(response => {
                const analysesList = response.data;
                setAnalysesOptions(analysesList.map((analysis: Analysis) => ({
                    label: analysis.name,
                    value: analysis.id,
                })));


            }).catch(error => {

            });
        };

        init();


    }, [companyName, form, uri]);


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const { date, storages } = values;


            console.log(dayjs(date))


            const utcDate = dayjs(date).utc(true).hour(12).minute(0).second(0).millisecond(0);



            console.log(utcDate)

            const resultDtoCreate: ResultDtoCreate = {
                factoryName: companyName ? companyName : "",
                storages: storages.map((storage: { analysesId: number }) => storage.analysesId), // Convert to array of numbers
            };

            console.log(resultDtoCreate)

            const endpoint = `linear/solve`;
            const method = authApi.post;

            console.log(resultDtoCreate)

            const loadingMessageKey = 'loadingMessage';

            message.loading({ content: 'Operation loading...', key: loadingMessageKey, duration: 0 });

            method(endpoint, resultDtoCreate).then(response => {
                const solutions: Solutions[] = response.data;
                message.success({ content: 'Operation successful!', key: loadingMessageKey, duration: 2 });
                onSubmit(solutions);
            }).catch(() => {
                message.error({ content: 'Operation failed', key: loadingMessageKey, duration: 2 });

            });
        });
    };


    // Update the disabled state based on the number of analyses in the form
    const onFieldsChange = () => {
        const storages = form.getFieldValue('storages');

        if (!storages || storages.length === 0 )
            setIsSubmitDisabled(true)
        else if (storages[0] === undefined)
            setIsSubmitDisabled(true)
        else
            setIsSubmitDisabled(false)
    };


    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: '50px',
                flex: "auto",
            }}
        >
            <Space size={"large"}>
                <Col>
                    <Form form={form} onFinish={handleSubmit} layout="vertical" autoComplete="off"
                          onFieldsChange={onFieldsChange}>


                        <Form.List name="storages">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <Row key={key} gutter={10} align="middle">
                                            <Col flex="auto">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'analysesId']}
                                                    label="Analyses Name"
                                                    rules={[{required: true, message: 'Missing analyses Name'}]}
                                                >
                                                    <Select
                                                        placeholder="Select analysis name"
                                                        options={analysesOptions} // Populate dropdown with options
                                                        style={{width: '100%'}}
                                                        optionFilterProp="label"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}>
                                            Add Analyses
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form>
                </Col>
                <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Space size="large">
                        <Button type="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>Submit</Button>
                    </Space>

                </Col>
            </Space>
        </div>
    );
}

export default ResultForm;