// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FactoryDetails.module.css */

.FactoryDetails_factory-details-container__QCiee {
    /* Correct CSS properties with kebab-case */
    padding: 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

/* For 720p resolution screens */
@media (max-width: 1300px) {
    .FactoryDetails_factory-details-container__QCiee {
        width: 100%;
    }
}

/* For screens between 1301px and 1500px in width */
@media (min-width: 1301px) and (max-width: 1500px) {
    .FactoryDetails_factory-details-container__QCiee {
        width: 90%;
    }
}

/* For screens wider than 1500px */
@media (min-width: 1501px) {
    .FactoryDetails_factory-details-container__QCiee {
        width: 80%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/factory_details/FactoryDetails.module.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,2CAA2C;IAC3C,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA,gCAAgC;AAChC;IACI;QACI,WAAW;IACf;AACJ;;AAEA,mDAAmD;AACnD;IACI;QACI,UAAU;IACd;AACJ;;AAEA,kCAAkC;AAClC;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":["/* FactoryDetails.module.css */\r\n\r\n.factory-details-container {\r\n    /* Correct CSS properties with kebab-case */\r\n    padding: 40px;\r\n    border-radius: 8px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: white;\r\n}\r\n\r\n/* For 720p resolution screens */\r\n@media (max-width: 1300px) {\r\n    .factory-details-container {\r\n        width: 100%;\r\n    }\r\n}\r\n\r\n/* For screens between 1301px and 1500px in width */\r\n@media (min-width: 1301px) and (max-width: 1500px) {\r\n    .factory-details-container {\r\n        width: 90%;\r\n    }\r\n}\r\n\r\n/* For screens wider than 1500px */\r\n@media (min-width: 1501px) {\r\n    .factory-details-container {\r\n        width: 80%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"factory-details-container": `FactoryDetails_factory-details-container__QCiee`
};
export default ___CSS_LOADER_EXPORT___;
